(function($) {

    $(window).on('load', function () {

        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });

        $('a[href*="#"]').on('click', function(e){
            e.preventDefault();
            $(window).scrollTop($($(this).attr('href')).offset().top - $('header').outerHeight());
        });

        // Inline Video Funcionality
        $(document).on('click', '.inline-video-trigger', function () {
            if ($(this).data('video-id')) {
                if ($(this).hasClass('vimeo')) {
                    var iframeHTML = '<iframe src="https://www.vimeo.com/embed/' + $(this).attr('data-video-id') + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
                } else {
                    var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                }

                $(this).parent('.video-preview-container').find('.inline-video-trigger').hide();
                $(this).parent('.video-preview-container').find('.overlay').hide();
                $(this).parent('.video-preview-container').find('iframe').remove();
                $(this).parent('.video-preview-container').append(iframeHTML);
            } else {
                console.error('no video ID provided.');
            }
        });

        // Slick Slider (jQuery) - Remove these if not in use 
        $('.testimonials .slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 600,
            dots: false,
            autoplay: true,
            autoplaySpeed: 5000,
            prevArrow: $('.testimonials .prev'),
            nextArrow: $('.testimonials .next')
        });

        // Gallery Slider
        $('.gallery .slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 600,
            fade: true,
            prevArrow: $('.gallery .prev'),
            nextArrow: $('.gallery .next'),
            asNavFor: '.gallery .slider-controls'
        });
        $('.gallery .slider-controls').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.gallery .slider',
            arrows: false,
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            infinit: true
        });

        // Gallery Slider
        $('.video-gallery .slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: 'progressive',
            speed: 600,
            prevArrow: $('.video-gallery .prev'),
            nextArrow: $('.video-gallery .next'),
            fade: true,
            asNavFor: '.video-gallery .slider-controls'
        });

        $('.video-gallery .slider-controls').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.video-gallery .slider',
            lazyLoad: 'progressive',
            dots: false,
            arrows: false,
            focusOnSelect: true,
            infinit: true,
        });
    })

    setTimeout(function () {
        $('.phone-text em').each(function () {
            var unsliced = $(this).text();
            var sliced = unsliced.slice(0, -2) + "...";
            $(this).text(sliced);
            var linked = "tel:" + unsliced.replace(/\s/g, '');
            $(this).click(function () {
                if ($(window).width() < 1000) {
                    window.location.href = linked;
                } else {
                    $(this).text(unsliced);
                }
            });
        });

    }, 3000);

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    });


})( jQuery );
